
import { cloneDeep } from 'lodash-es';
import { CustomField, Product, VariantItem } from './product.types';


/**
 * Represents a selection of a product item and its quantity.
 */
interface Selection {
    /**
     * The ID of the selected product item.
     */
    itemId: number;
    /**
     * An array of quantities for the selected product item.
     * The index of the array corresponds to the index of the product item's options.
     * For example, if the product item has two options (size and color), then the quantity array will have two elements.
     */
    quantity: number[];
}

/**
 * Represents an order of a product.
 */
export class ProductOrder {

    /** The unique identifier of the product order. */
    public id: number;

    /** The product being ordered. */
    public product: Product;

    /** The quantity of the product being ordered. */
    public quantity: number;

    /** The quantity of the product that has been refunded. */
    public refundedQuantity: number;

    /** The quantity of the product that has been requested for refund. */
    public requestedRefundQuantity: number;

    /** The quantity of the product that has been returned. */
    public returnedQuantity: number;

    /** The quantity of the product that has been requested for return. */
    public requestedReturnedQuantity: number;

    /** The upselling items selected for the product order. */
    public selectionUpselling: Selection[];

    /** The variant item selected for the product order. */
    public selectedVariant: VariantItem;

    /** The employee selected for the product order. */
    public selectedEmployee: string;

    /** The list of available employees for the product order. */
    public availableEmployees: string[];

    /** The service time slot selected for the product order. */
    public selectedServiceTimeSlot: Date;

    /** The Google Calendar ID associated with the product order. */
    public googleCalendarId: string;

    /** The Google Calendar event ID associated with the product order. */
    public googleCalendarEventId: string;

    /** The Google Calendar event associated with the product order. */
    public googleCalendarEvent?: any;

    /** The status of the product order. */
    public status: string;

    /**
     * Creates a new instance of ProductOrder.
     * @param product The product being ordered.
     * @param productOrder The product order to clone.
     */
    constructor(product?: Product, productOrder?: ProductOrder) {
        if (product) {
            this.id = Date.now();
            this.product = product;
            this.quantity = 1;
            this.refundedQuantity = 0;
            this.requestedRefundQuantity = 0;
            this.returnedQuantity = 0;
            this.requestedReturnedQuantity = 0;
            this.selectionUpselling = [];
            product.upsellingItems.forEach((upsellingItem, upsellingItemIdx) => {
                this.selectionUpselling.push({
                    itemId: upsellingItemIdx,
                    quantity: new Array(upsellingItem.options.length).fill(0)
                });
            });
            this.selectedVariant = product.variantItems.find((elem, idx, obj) => elem.valid as boolean);
            this.selectedEmployee = '';
            this.availableEmployees = [];
            this.selectedServiceTimeSlot = null;
            this.googleCalendarId = null;
            this.googleCalendarEventId = null;
            this.status = null;
        } else if (productOrder) {
            this.id = productOrder.id;
            this.product = new Product().deserialize(productOrder.product);
            this.quantity = productOrder.quantity;
            this.refundedQuantity = productOrder.refundedQuantity;
            this.requestedRefundQuantity = productOrder.requestedRefundQuantity;
            this.returnedQuantity = productOrder.returnedQuantity;
            this.requestedReturnedQuantity = productOrder.requestedReturnedQuantity;
            this.selectionUpselling = cloneDeep(productOrder.selectionUpselling);
            this.selectedVariant = cloneDeep(productOrder.selectedVariant);
            this.selectedEmployee = productOrder.selectedEmployee;
            this.availableEmployees = cloneDeep(productOrder.availableEmployees);
            this.selectedServiceTimeSlot = cloneDeep(productOrder.selectedServiceTimeSlot);
            this.googleCalendarId = productOrder.googleCalendarId;
            this.googleCalendarEventId = productOrder.googleCalendarEventId;
            this.status = productOrder.status;
        } else {
            console.log('Error: constructor of ProductOrder invoked without passing a product or a productOrder.');
        }
    }

    /**
     * Returns the unit price of the selected product variant, including any additional costs from custom fields and upselling options.
     * @returns The unit price of the selected product variant.
     */
    get unitPrice(): number {
        let price = this.selectedVariant.discountPrice ? this.selectedVariant.discountPrice : this.selectedVariant.price;
        if (this.selectedVariant.customFieldValues?.length > 0) {
            for (let i = 0; i < this.selectedVariant.customFieldValues.length; i++) {
                const customField: CustomField = this.product.customFields?.length > i ? this.product.customFields[i] : null;
                if (!customField) {
                    continue;
                }
                const selectedValue: any = this.selectedVariant.customFieldValues[i];
                if (Array.isArray(selectedValue)) {
                    for (const value of selectedValue) {
                        price += customField.extras?.acceptedValues?.find((elem) => elem.value === value)?.price || 0;
                    }
                } else {
                    if (customField.type === 'input') {
                        // No cost if the input is empty and optional
                        if (customField.required || (selectedValue && selectedValue !== '')) {
                            price += customField.price || 0;
                        }
                    } else {
                        price += customField.extras?.acceptedValues?.find((elem) => elem.value === selectedValue)?.price || 0;
                    }
                }
            }
        }
        this.selectionUpselling.forEach((selection, selectionIdx) => {
            selection.quantity.forEach((quantity, optionIdx) => {
                price += this.product.upsellingItems[selectionIdx].options[optionIdx].price * quantity;
            });
        });
        return price;
    }

    /**
     * Calculates the total price of a product order by multiplying the unit price by the quantity.
     * @returns The total price of the product order.
     */
    get price(): number {
        return this.unitPrice * this.quantity;
    }

    /**
     * Returns the total weight of the product order, calculated by multiplying the weight of the selected variant by the quantity.
     * @returns The total weight of the product order.
     */
    get weight(): number {
        return this.selectedVariant.dimensions.weight * this.quantity;
    }

    /**
     * Returns the total duration of the product order, taking into account the selected variant and any upselling options.
     * @returns The total duration of the product order.
     */
    get duration(): number {
        let duration = this.selectedVariant.duration;
        this.selectionUpselling.forEach((selection, selectionIdx) => {
            selection.quantity.forEach((quantity, optionIdx) => {
                duration += this.product.upsellingItems[selectionIdx].options[optionIdx].duration * quantity;
            });
        });
        return duration * this.quantity;
    }

    /**
     * Returns a JSON representation of the ProductOrder object.
     * @returns {any} JSON representation of the ProductOrder object.
     */
    toJson(): any {
        return {
            id: this.id,
            product: this.product,
            quantity: this.quantity,
            refundedQuantity: this.refundedQuantity,
            requestedRefundQuantity: this.requestedRefundQuantity,
            returnedQuantity: this.returnedQuantity,
            requestedReturnedQuantity: this.requestedReturnedQuantity,
            unitPrice: this.unitPrice,
            price: this.price,
            duration: this.duration,
            selectionUpselling: this.selectionUpselling,
            selectedVariant: this.selectedVariant,
            selectedEmployee: this.selectedEmployee,
            availableEmployees: this.availableEmployees,
            selectedServiceTimeSlot: this.selectedServiceTimeSlot,
            googleCalendarId: this.googleCalendarId,
            googleCalendarEventId: this.googleCalendarEventId,
            status: this.status
        };
    }

}
