import { Injectable } from "@angular/core";
import { FuseConfirmationService } from "@fuse/services/confirmation";
import { TranslocoService } from "@jsverse/transloco";

/**
 * Service for managing error dialogs.
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    /**
   * Creates an instance of DialogError.
   *
   * @param _fuseConfirmationService The confirmation service.
   * @param _translocoService The transloco service.
   */
    constructor(
        private _fuseConfirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService
    ) {

    }

    /**
     * Shows a dialog with the error message.
     *
     * @param error The error object or message.
     */
    show(error: any): void {
        let errorMessage: string;
        if (typeof error === 'string') {
            errorMessage = error;
        } else {
            errorMessage = error.error?.message || error.message || error;
        }
        if (!errorMessage || typeof errorMessage !== 'string') {
            return;
        }
        this._fuseConfirmationService.open({
            title: this._translocoService.translate('services.dialog-error.title'),
            message: errorMessage,
            icon: {
                show: true,
                name: 'heroicons_outline:exclamation-triangle',
                color: 'error'
            },
            actions: {
                confirm: {
                    show: true,
                    label: this._translocoService.translate('services.dialog-error.btn-ok'),
                    color: 'warn'
                },
                cancel: {
                    show: false
                }
            }
        });
    }
}