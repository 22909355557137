
import { ProductOrder } from '../product/product-order.types';
import { ShippoShippingRate } from '../shipping/shipping-rate-shippo.types';
import { FlatShippingRate } from '../shipping/shipping-rate-flat.types';
import { Deserializable } from '../utils/deserializable.types';


/**
 * Represents a Qart order.
 */
export class QartOrder implements Deserializable {

    /**
     * The ID of the order.
     */
    public id: string;

    /**
     * The source of the order.
     */
    public source: string;

    /**
     * The list of product orders in the order.
     */
    public productOrders: ProductOrder[];

    /**
     * The delivery method of the order.
     */
    public deliveryMethod: string;

    /**
     * The shipping method of the order.
     */
    public shippingMethod: 'shippo' | 'flat';

    /**
     * The Shippo shipping rate of the order.
     */
    public shippoShippingRate: ShippoShippingRate;

    /**
     * The flat shipping rate of the order.
     */
    public flatShippingRate: FlatShippingRate;

    /**
     * The percentage of shipping paid for the order.
     */
    public shippingPercentagePaid: number;

    /**
     * The pickup date of the order.
     */
    public pickupDate: Date;

    /**
     * The currency of the order.
     */
    public currency: string;

    /**
     * Creates a new instance of the QartOrder class.
     * @param qartOrder The QartOrder object to copy properties from.
     */
    constructor(qartOrder?: QartOrder) {
        if (!qartOrder) {
            this.source = 'admin_website';
            this.productOrders = [];
            this.deliveryMethod = 'collect';
            this.shippingMethod = null;
            this.shippoShippingRate = null;
            this.flatShippingRate = null;
            this.shippingPercentagePaid = 1;
            this.pickupDate = null;
            this.currency = 'EUR';
        } else {
            this.id = qartOrder.id;
            this.source = qartOrder.source;
            this.productOrders = qartOrder.productOrders.map(productOrder => new ProductOrder(null, productOrder));
            this.deliveryMethod = qartOrder.deliveryMethod;
            this.shippingMethod = qartOrder.shippingMethod;
            this.shippoShippingRate = qartOrder.shippoShippingRate;
            this.flatShippingRate = qartOrder.flatShippingRate;
            this.shippingPercentagePaid = qartOrder.shippingPercentagePaid;
            this.pickupDate = qartOrder.pickupDate;
            this.currency = qartOrder.currency;
        }
    }

    /**
     * Gets the number of products in the order.
     */
    get numberOfProducts(): number {
        return this.productOrders.map(elem => elem.quantity).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Gets whether the order is shippable.
     */
    get isShippable(): boolean {
        return this.productOrders.filter(productOrder => productOrder.product.shippable).length > 0;
    }

    /**
     * Gets the subtotal of the order.
     */
    get subtotal(): number {
        return this.productOrders.map(productOrder => productOrder.price).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Gets the weight of the order.
     */
    get weight(): number {
        return this.productOrders.map(productOrder => productOrder.weight).reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Gets the total price of the order.
     */
    get total(): number {
        if (this.deliveryMethod === 'delivery') {
            if (this.shippingMethod === 'shippo') {
                if (this.shippoShippingRate?.amount) {
                    return this.subtotal + (parseFloat(this.shippoShippingRate?.amount?.toString()) * this.shippingPercentagePaid);
                }
            } else if (this.shippingMethod === 'flat') {
                if (this.flatShippingRateAmount) {
                    return this.subtotal + this.flatShippingRateAmount;
                }
            } else {
                return this.subtotal;
            }
        }
        return this.subtotal;
    }

    /**
     * Gets the flat shipping rate amount of the order.
     */
    get flatShippingRateAmount(): number {
        // A price of -1 means that the price is undefined
        if (!this.flatShippingRate) {
            return null;
        }
        if (!this.flatShippingRate.conditionsApply) {
            return this.flatShippingRate.price;
        }
        let prices: number[] = this.flatShippingRate.conditions.map(condition => {
            if (condition.criteria === 'nbItemsInBaskets') {
                if (this.numberOfProducts <= condition.threshold) {
                    return condition.priceBelowThreshold;
                } else if (this.numberOfProducts > condition.threshold) {
                    return condition.priceAboveThreshold;
                } else {
                    return -1;
                }
            } else if (condition.criteria === 'totalBasket') {
                if (this.subtotal < condition.threshold) {
                    return condition.priceBelowThreshold;
                } else if (this.subtotal >= condition.threshold) {
                    return condition.priceAboveThreshold;
                } else {
                    return -1;
                }
            } else if (condition.criteria === 'weightBasket') {
                const w: number = this.weight;
                const range: any = condition.ranges.find(r => r.min <= w && w <= r.max);
                if (range) {
                    return range.price;
                } else {
                    return -1;
                }
            }
        });

        for (let i = 0; i < prices.length; i++) {
            if (prices[i] == null) {
                prices[i] = -1;
            }
        }
        prices = prices.filter(price => price !== -1);
        if (prices.length === 0) {
            return -1;
        } else {
            return Math.min(...prices);
        }
    }

    /**
     * Deserializes the input object into the QartOrder object.
     * @param input The input object to deserialize.
     * @returns The deserialized QartOrder object.
     */
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    /**
     * Gets the number of a specific product in the order.
     * @param product The product to get the number of.
     * @returns The number of the specified product in the order.
     */
    getNumberOfProduct(product): number {
        return this.productOrders
            .filter(x => x.product._id === product._id)
            .map(x => x.quantity)
            .reduce((acc, cur) => acc + cur, 0);
    }

    /**
     * Converts the QartOrder object to a JSON object.
     * @returns The QartOrder object as a JSON object.
     */
    toJson(): any {
        return {
            id: this.id,
            source: this.source,
            productOrders: this.productOrders.map(productOrder => productOrder.toJson()),
            deliveryMethod: this.deliveryMethod,
            shippingMethod: this.shippingMethod,
            shippoShippingRate: this.shippoShippingRate,
            flatShippingRate: this.flatShippingRate,
            flatShippingRateAmount: this.flatShippingRateAmount,
            shippingPercentagePaid: this.shippingPercentagePaid,
            pickupDate: this.pickupDate,
            numberOfProducts: this.numberOfProducts,
            isShippable: this.isShippable,
            subtotal: this.subtotal,
            total: this.total,
            weight: this.weight,
            currency: this.currency
        };
    }
}
