import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { DEFAULT_HTTP_OPTIONS_5_MINUTES, DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';


/**
 * EmployeeService is responsible for managing employee-related data and API calls.
 */
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  /**
   * The current merchant.
   */
  private _merchant: Merchant;

  /**
   * The current employee.
   */
  private _employee: BehaviorSubject<Employee> = new BehaviorSubject(null);

  /**
   * The list of employees.
   */
  private _employees: BehaviorSubject<Employee[]> = new BehaviorSubject(null);
  
  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'employee';

  /**
    * Creates an instance of EmployeeService.
    * @param {HttpClient} _httpClient - The HttpClient service to make HTTP requests.
    * @param {MerchantService} _merchantService - The MerchantService to get the merchant information.
    * @param {CacheService} _cacheService - The CacheService to cache the employee data.
    * @memberof EmployeeService
    */
  constructor(
    private _httpClient: HttpClient,
    private _merchantService: MerchantService,
    private _cacheService: CacheService,
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if ( reload ) {
          this._employee.next(null);
          this._employees.next(null);
          this.getEmployees()
            .subscribe();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current list of employees.
   * @returns An observable of the current list of employees.
   */
  get employees$(): Observable<Employee[]> {
    return this._employees.asObservable();
  }

  /**
   * Returns an observable of the current employee.
   * @returns An observable of the current employee.
   */
  get employee$(): Observable<Employee> {
    return this._employee.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Retrieves an employee by ID.
   * @param employeeId The ID of the employee to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested employee object.
   */
  getEmployee(employeeId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee> {
    // If there is no merchant, return null
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the employee is already loaded, return it
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId })) {
      const employee: Employee = this._cacheService.get(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId });
      if (options.forcePropagate) {
        this._employee.next(employee);
      }
      return of(employee);
    }
    // Otherwise, load the employee from the array of employees previously loaded
    return this._employees.pipe(
      take(1),
      map((employees: Employee[]) => {
        const employee = employees.find(item => item._id === employeeId) || null;
        return employee;
      }),
      switchMap((employee: Employee) => {
        if ( !employee ) {
          console.error(`Error: Cannot find employee with id of ${employeeId}!`);
          return of(null);
        }
        // Handle the logic of the cache and the propagation
        if (options.cache) {
          this._cacheService.set(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId }, employee, options.expire);
        }
        if (options.propagate) {
          this._employee.next(employee);
        }
        return of(employee);
      })
    );
  }

  /**
   * Returns an array of employees for the current merchant.
   * @param options - The options related to cache and propagation.
   * @returns An array of employees.
   */
  getEmployees(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee[]> {
    // If there is no merchant, return an empty array
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the employees are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'employees', cacheParams)) {
      const employees: Employee[] = this._cacheService.get(this._cacheNamespace, 'employees', cacheParams);
      if (options.forcePropagate) {
        this._employees.next(employees);
      }
      return of(employees);
    }
    // Otherwise, load the employees from the backend
    return this._httpClient.get<Employee[]>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(`Error: ${error}`);
        return of([]);
      }),
      // Handle the logic of the cache and the propagation
      tap((employees: Employee[]) => {
        if (options.cache) {
          this._cacheService.set(this._cacheNamespace, 'employees', cacheParams, employees, options.expire);
        }
        if (options.propagate) {
          this._employees.next(employees);
        }
      })
    );
  }

  /**
   * Adds a new employee.
   * @param newEmployee The new employee.
   * @param forceNewCalendar Whether to create a new calendar for the employee.
   * @param options - The options related to cache and propagation.
   * @returns The added Employee object.
   */
  addEmployee(
    newEmployee: Employee,
    forceNewCalendar: boolean=undefined,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE
  ): Observable<Employee> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.employees$.pipe(
      take(1),
      switchMap((employees: Employee[]) => this._httpClient.post<Employee>(
        `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees`,
        {
          newEmployee,
          forceNewCalendar
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error: ${error}`);
            return of(null);
          }),
          // Handle the logic of the cache and the propagation
          tap((addedEmployee: Employee) => {
            if (!addedEmployee) {
              return;
            }
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId: addedEmployee._id }, addedEmployee, options.expire);
              this._cacheService.set(this._cacheNamespace, 'employees', { merchantId: this._merchant._id }, [addedEmployee, ...employees], options.expire);
            }
            if (options.propagate) {
              this._employee.next(addedEmployee);
              this._employees.next([addedEmployee, ...employees]);
            }
          })
        )
      )
    );
  }

  /**
   * Updates an employee.
   * @param newEmployee The updated employee object.
   * @param options - The options related to cache and propagation.
   * @returns The updated employee object.
   */
  updateEmployee(newEmployee: Employee, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.employees$.pipe(
      take(1),
      switchMap(employees => this._httpClient.put<Employee>(
        `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees/${newEmployee._id}`,
        {
          newEmployee
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error: ${error}`);
            return of(null);
          }),
          // Handle the logic of the cache and the propagation
          tap((updatedEmployee: Employee) => {
            if (!updatedEmployee) {
              return;
            }
            // Replace the employee in the list of employees
            const employeeIndex = employees.findIndex(e => e._id === updatedEmployee._id);
            if (employeeIndex !== -1) {
              employees[employeeIndex] = updatedEmployee;
            }
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId: updatedEmployee._id }, updatedEmployee, options.expire);
              this._cacheService.set(this._cacheNamespace, 'employees', { merchantId: this._merchant._id }, employees, options.expire);
            }
            if (options.propagate) {
              this._employee.next(updatedEmployee);
              this._employees.next(employees);
            }
          })
        )
      )
    );
  }

  /**
   * Updates the employees.
   * @param newEmployees An array of Employee objects to be updated.
   * @param options - The options related to cache and propagation.
   * @returns The updated employee objects.
   */
  updateEmployees(newEmployees: Employee[], options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee[]> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.employees$.pipe(
      take(1),
      switchMap(employees =>
        forkJoin(
          newEmployees.map(newEmployee => this._httpClient.put<Employee>(
            `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees/${newEmployee._id}`,
            {
              newEmployee
            },
            {
              headers: new HttpHeaders().set('Content-Type', 'application/json'),
              withCredentials: true
            }
          ).pipe(
            catchError((error: HttpErrorResponse) => {
              console.error(`Error: ${error}`);
              return of(null);
            }),
            // Handle the logic of the cache and the propagation
            tap((updatedEmployee: Employee) => {
              if (!updatedEmployee) {
                return;
              }
              // Replace the employee in the list of employees
              const employeeIndex = employees.findIndex(e => e._id === updatedEmployee._id);
              if (employeeIndex !== -1) {
                employees[employeeIndex] = updatedEmployee;
              }
              if (options.cache) {
                this._cacheService.set(this._cacheNamespace, 'employees', { merchantId: this._merchant._id }, employees, options.expire);
              }
              if (options.propagate) {
                this._employees.next(employees);
              }
            })
          ))
        )
      )
    );
  }

  /**
   * Deletes an employee by ID.
   * @param employeeId - The ID of the employee to delete.
   * @param options - The options related to cache and propagation.
   * @returns The deleted Employee object.
   */
  deleteEmployee(employeeId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Employee> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.employees$.pipe(
      take(1),
      switchMap((employees: Employee[]) => this._httpClient.delete<Employee>(
        `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees/${employeeId}`,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error: ${error}`);
            return of(null);
          }),
          // Handle the logic of the cache and the propagation
          tap((deletedEmployee: Employee) => {
            if (!deletedEmployee) {
              return;
            }
            const employeeIndex = employees.findIndex(e => e._id === employeeId);
            if (employeeIndex !== -1) {
              employees.splice(employeeIndex, 1);
            }
            if (options.cache) {
              this._cacheService.delete(this._cacheNamespace, 'employee', { merchantId: this._merchant._id, employeeId: deletedEmployee._id });
              this._cacheService.set(this._cacheNamespace, 'employees', { merchantId: this._merchant._id }, employees, options.expire);
            }
            if (options.propagate) {
              this._employee.next(null);
              this._employees.next(employees);
            }
          })
        )
      )
    );
  }

  /**
   * Get the calendar events for a given employee.
   * @param employeeId - The ID of the employee for whom to retrieve calendar events.
   * @param options - The options related to cache and propagation.
   * @returns The calendar events for the given employee.
   */
  getEmployeeCalendarEvents(employeeId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<any> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the events are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, employeeId };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'events', cacheParams)) {
      const events: any[] = this._cacheService.get(this._cacheNamespace, 'events', cacheParams);
      return of(events);
    }
    // Otherwise, load the events from the backend
    return this._httpClient.get<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/calendars/employee/${employeeId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(`Error: ${error}`);
        return of([]);
      }),
      // Handle the logic of the cache and the propagation
      tap((events: any[]) => {
        if (options.cache) {
          this._cacheService.set(this._cacheNamespace, 'events', cacheParams, events, options.expire);
        }
      })
    );
  }

  /**
   * Promotes an employee with the given ID.
   * @param employeeId The ID of the employee to promote.
   * @param options - The options related to cache and propagation.
   * @returns The promoted employee object.
   */
  promoteEmployee(employeeId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<null> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.put<any>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/employees/${employeeId}/promote`,
      { },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(`Error: ${error}`);
        return of(null);
      }),
      tap(() => this.getEmployees(options).subscribe())
    );
  }

  /**
   * Adds pictures to the merchant's photos.
   * @param file - The file to be uploaded.
   * @returns The HTTP response events.
   */
  addPictures(file: File): Observable<any> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    const fd = new FormData();
    if (file !== null) {
      fd.append('files', file, file.name);
    }
    return this._httpClient.post(`${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/photos`, fd, {
      headers: new HttpHeaders(),
      reportProgress: true,
      withCredentials: true,
      observe: 'events'
    });
  }

  /**
   * Removes a picture with the given filename from the merchant's photos.
   * @param filename - The name of the file to be removed.
   * @returns The HTTP DELETE request.
   */
  removePicture(filename: string): Observable<any> {
    if ( !this._merchant ) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.delete(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/photos/${filename}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      }
    );
  }
  
  /**
   * Get the URLs of the photos of a employee.
   * @param {Employee} employee - The employee.
   * @returns {string[]} The URLs of the photos.
   */
  getPhotoUrls(employee: Employee): string[] {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return [];
    }
    let urls: string[] = [];
    if (employee.photos && employee.photos.length > 0) {
      urls = employee.photos.map((photo) => {
        if (photo.source !== 'qart') {
          return photo.url;
        } else {
          const webpAvailable: boolean = photo.webpFilename && photo.webpFilename !== '';
          if (webpAvailable) {
            return `${environment.qart.imagesUrl}/${this._merchant.name}/${photo.webpFilename}`;
          } else {
            return `${environment.qart.imagesUrl}/${this._merchant.name}/${photo.filename}`;
          }
        }
      });
    }
    return urls;
  }

}
