import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideEnvironmentInitializer, EnvironmentProviders, inject, Provider } from '@angular/core';
import { authInterceptor } from 'app/core/auth/auth.interceptor';
import { AuthService } from 'app/core/auth/auth.service';

/**
 * Provides authentication related dependencies.
 * 
 * @returns An array of providers for authentication.
 */
export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        provideHttpClient(withInterceptors([authInterceptor])),
        provideEnvironmentInitializer(() => inject(AuthService))
    ];
};
