import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { environment } from '@env/environment';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { BookComponent } from 'app/layout/common/book/book.component';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { User as FirebaseUser } from 'firebase/auth';
import { debounceTime, Subject, takeUntil } from 'rxjs';

/**
 * Class representing the Classy Layout Component.
 */
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports    : [
        TranslocoModule,
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        BookComponent,
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        UserComponent
    ]
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    /**
     * A boolean indicating whether the screen is small or not.
     */
    isScreenSmall: boolean;

    /**
     * The navigation data.
     */
    navigation: Navigation;

    /**
     * The user data.
     */
    user: User;

    /**
     * The current merchant data.
     */
    merchant: Merchant;

    /**
     * The list of all merchants.
     */
    merchants: Merchant[];

    /**
     * The domain name.
     */
    domain: string = environment.qart.domain;

    /**
     * A subject used to unsubscribe from all subscriptions.
     */
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Creates an instance of ClassyLayoutComponent.
     *
     * @param _navigationService - The NavigationService instance.
     * @param _router - The Router instance.
     * @param _userService - The UserService instance.
     * @param _merchantService - The MerchantService instance.
     * @param _fuseMediaWatcherService - The FuseMediaWatcherService instance.
     * @param _changeDetectorRef - The ChangeDetectorRef instance.
     * @param _fuseNavigationService - The FuseNavigationService instance.
     * @param _translocoService - The TranslocoService instance.
     */
    constructor(
        private _navigationService: NavigationService,
        private _router: Router,
        private _userService: UserService,
        private _merchantService: MerchantService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Returns the current year as a number.
     * @returns The current year.
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * OnInit lifecycle hook
     * 
     * Subscribes to various services to get the necessary data for rendering the layout.
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe(async (user: FirebaseUser) => {
                this.user = await this._userService.getQartUser();
            });

        // Subscribe to the merchant service
        this._merchantService.merchant$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((merchant: Merchant) => {
                this.merchant = merchant;
            });

        // Subscribe to the merchant service
        this._merchantService.merchants$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((merchants: Merchant[]) => {
                this.merchants = merchants;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        
        // Subscribe to language changes
        this._translocoService.langChanges$
            .pipe(debounceTime(2000))
            .subscribe((activeLang) => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Unsubscribes from all subscriptions to prevent memory leaks.
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggles the opened status of a navigation component by name.
     * @param name - The name of the navigation component.
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    /**
     * Changes the current merchant to the provided merchant.
     * @param merchant - The merchant to set as the current merchant.
     */
    changeMerchant(merchant: Merchant): void {
        this._merchantService.setCurrentMerchant(merchant);
        this._router.navigate(['/dashboard']);
    }

    /**
     * Handles the activation of a component and pushes the page name to the data layer if it exists.
     * @param event - The event that triggered the activation.
     * @param outlet - The outlet where the component was activated.
     */
    onActivate(event, outlet) {
        if (window["dataLayer"]) {
            window["dataLayer"].push({ 
                page_name: outlet.activatedRoute.snapshot.data?.pageName
            });
        }
    }

    /**
     * Opens the website of the current merchant in a new tab.
     */
    visitWebsite(): void {
        window.open(`https://${this.merchant.name}.${this.domain}`, '_blank');
    }
}
