
/**
 * Object containing all the paths used in the application.
 */
export const paths: any = {
    null: '',
    auth: {
        signedInRedirect: 'signed-in-redirect',
        confirmationRequired: 'confirmation-required',
        emailVerified: 'email-verified',
        forgotPassword: 'forgot-password',
        resetPassword: 'reset-password',
        redirect: 'redirect',
        signIn: 'sign-in',
        signUp: 'sign-up',
        signOut: 'sign-out',
        offboarding: 'offboarding',
        mfa: 'mfa',
        openMerchant: 'open-merchant'
    },
    onboarding: {
        start: 'onboarding/start',
        name: 'onboarding/name',
        domain: 'onboarding/domain',
        online: 'onboarding/online',
        address: 'onboarding/address',
        phone: 'onboarding/phone',
        done: 'onboarding/done',
        language: 'onboarding/language',
        congrats: 'onboarding/congrats',
        payment: 'onboarding/payment',
        openai: 'onboarding/openai',
        ai: 'onboarding/ai',
        media: 'onboarding/media',
        aboutUs: 'onboarding/about-us'
    },
    welcome: 'welcome',
    dashboard: 'dashboard',
    merchant: 'merchant',
    customers: 'customers',
    analytics: 'analytics',
    connect: 'connect',
    products: 'products',
    website: 'website',
    categories: 'categories',
    orders: 'orders',
    settings: 'settings',
    agenda: 'agenda',
    import_picture: 'import-picture',
    import_file: 'import-file',
    import_woocommerce: 'import-woocommerce',
    import_feed: 'import-feed',
    helpCenter: 'help-center',
    blogs: 'blogs',
    coupons: 'coupons'
};