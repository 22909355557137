import { provideEnvironmentInitializer, EnvironmentProviders, inject, Provider } from '@angular/core';
import { IconsService } from 'app/core/icons/icons.service';

/**
 * Provides an array of providers or environment providers for icons.
 * @returns An array of providers or environment providers.
 */
export const provideIcons = (): Array<Provider | EnvironmentProviders> =>
{
    return [provideEnvironmentInitializer(() => inject(IconsService))];
};
