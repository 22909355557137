import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MerchantService } from '@core//merchant/merchant.service';
import { CacheService } from '@core/cache/cache.service';
import { Merchant } from '@core/merchant/merchant.types';
import { Order } from '@core/order/order.types';
import { QartOrder } from '@core/order/qart-order.types';
import { ProductType } from '@core/product/product.types';
import { Settings } from '@core/settings/settings.types';
import { FlatShippingRate } from '@core/shipping/shipping-rate-flat.types';
import { ShippoShippingRate } from '@core/shipping/shipping-rate-shippo.types';
import { ErrorService } from '@core/utils/dialog-error';
import { DEFAULT_HTTP_OPTIONS_5_MINUTES, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import moment from 'moment';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';


/**
 * Service for managing orders.
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  /**
   * The merchant associated with the orders.
   */
  private _merchant: Merchant;

  /**
   * The currently selected order.
   */
  private _order: BehaviorSubject<Order | null> = new BehaviorSubject(null);

  /**
   * The total number of orders.
   */
  private _orderCount: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * The total number of products sold.
   */
  private _productSoldCount: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * The list of all orders.
   */
  private _orders: BehaviorSubject<Order[]> = new BehaviorSubject([]);

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'order';

  /**
   * Creates an instance of OrderService.
   *
   * @param _merchantService The merchant service.
   * @param _cacheService The cache service.
   * @param _httpClient The HTTP client.
   */
  constructor(
    private _merchantService: MerchantService,
    private _cacheService: CacheService,
    private _errorService: ErrorService,
    private _httpClient: HttpClient
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._order.next(null);
          this._orderCount.next(0);
          this._productSoldCount.next(0);
          this._orders.next([]);
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current orders.
   * @returns An observable of the current orders.
   */
  get orders$(): Observable<Order[]> {
    return this._orders.asObservable();
  }

  /**
   * Returns an observable of the current order.
   * @returns An observable of the current order.
   */
  get order$(): Observable<Order> {
    return this._order.asObservable();
  }

  /**
   * Returns an observable of the current order count.
   * @returns An observable of the current order count.
   */
  get orderCount$(): Observable<number> {
    return this._orderCount.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Rebuilds an order by creating a new QartOrder instance and converting refund created dates to Date objects.
   * @param order - The order to rebuild.
   * @returns The rebuilt order.
   */
  private _rebuildOrder(order: Order): Order {
    if (!order) {
      return null;
    }
    const qartOrder = new QartOrder(order.qartOrder);
    order.qartOrder = qartOrder;
    order.refunds?.forEach(refund => refund.created = new Date(refund.created * 1000));
    const conditions: any[] = order.qartOrder.flatShippingRate?.conditions ?? [];
    if (conditions?.length > 0) {
      for (const condition of conditions) {
        if (condition?.ranges?.length > 0) {
          for (const range of condition.ranges) {
            if (range.max == null) {
              range.max = Infinity;
            }
          }
        }
      }
    }
    return order;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Determines if an order is shippable.
   * @param order - The order to check.
   * @returns True if the order is shippable, false otherwise.
   */
  isShippableOrder(order: Order): boolean {
    return order.qartOrder.productOrders
      .some(productOrder => productOrder.product.type === ProductType.Product && productOrder.product.shippable);
  }

  /**
   * Checks if an order has both shippable and non-shippable products.
   * @param order - The order to check.
   * @returns True if the order has both shippable and non-shippable products, false otherwise.
   */
  hasMixedBasket(order: Order): boolean {
    const hasShippableProducts: boolean = order.qartOrder.productOrders
      .some(productOrder => productOrder.product.type === ProductType.Product && productOrder.product.shippable);
    const hasNonShippableProducts: boolean = order.qartOrder.productOrders
      .some(productOrder => productOrder.product.type === ProductType.Product && !productOrder.product.shippable);
    return hasShippableProducts && hasNonShippableProducts;
  }

  /**
   * Returns the selected shipping rate for an order.
   * @param order - The order to retrieve the selected rate from.
   * @returns The selected shipping rate, either a ShippoShippingRate or FlatShippingRate object, or null if no rate is found.
   */
  getSelectedRate(order: Order): ShippoShippingRate | FlatShippingRate {
    // Retrieve the selected rate
    if (order.qartOrder?.shippingMethod === 'shippo') {
      let selectedRate: ShippoShippingRate = null;
      const rateId: string = order.shipping?.shippo?.shippingLabel?.rate;
      if (rateId) {
        const rates: any[] = order.shipping?.shippo?.shipment?.rates;
        if (rates && rates.length > 0) {
          selectedRate = rates.find(rate => rate.object_id === rateId);
          // Compute the arrival date
          const shipmentDate: moment.Moment = moment(order.shipping?.shippo?.shipment.shipment_date);
          if (selectedRate.estimated_days) {
            selectedRate.arrives_by = shipmentDate.add(selectedRate.estimated_days, 'day').toISOString();
          }
        }
      }
      return selectedRate;
    } else if (order.qartOrder?.shippingMethod === 'flat') {
      const selectedRate: FlatShippingRate = order.qartOrder.flatShippingRate;
      return selectedRate;
    }
    return null;
  }

  /**
   * Returns the ShippoShippingRate or FlatShippingRate that was paid by the merchant for the given order.
   * The paid rate is the rate that was paid by the merchant
   * It may differ from the selected rate if the customer has selected a flat fee
   * and the merchant has paid a Shippo rate
   * @param order - The order to get the paid rate for.
   * @returns The ShippoShippingRate or FlatShippingRate that was paid by the merchant, or null if no paid rate was found.
   */
  getPaidRate(order: Order): ShippoShippingRate | FlatShippingRate {
    let paidRate: ShippoShippingRate = null;
    // Check if there is a Shippo rate that was paid by the merchant
    if (order.shipping?.shippo?.shippingLabel?.object_state === 'VALID' && order.shipping?.shippo?.shippingLabel?.status === 'SUCCESS') {
      if (order.qartOrder?.shippingMethod === 'shippo') {
        // The rate selected by the customer was the rate paid by the merchant
        return this.getSelectedRate(order);
      } else if (order.qartOrder?.shippingMethod === 'flat') {
        const rateId: string = order.shipping?.shippo?.shippingLabel?.rate;
        if (rateId) {
          const rates: any[] = order.shipping?.shippo?.shipment?.rates;
          if (rates && rates.length > 0) {
            paidRate = rates.find(rate => rate.object_id === rateId);
            // Compute the arrival date
            const shipmentDate: moment.Moment = moment(order.shipping?.shippo?.shipment.shipment_date);
            if (paidRate.estimated_days) {
              paidRate.arrives_by = shipmentDate.add(paidRate.estimated_days, 'day').toISOString();
            }
          }
        }
      }
    }
    return paidRate;
  }

  /**
   * Retrieves the selected return rate for an order.
   * @param order - The order to retrieve the return rate for.
   * @returns The selected return rate, or null if none is found.
   */
  getSelectedReturnRate(order: Order): ShippoShippingRate {
    // Retrieve the selected rate
    if (order.qartOrder?.shippingMethod === 'shippo') {
      let selectedReturnRate: ShippoShippingRate = null;
      const rateId: string = order.shipping?.shippo?.returnLabel?.rate;
      if (rateId) {
        const rates: any[] = order.shipping?.shippo?.returnShipment?.rates;
        if (rates) {
          selectedReturnRate = rates.find(rate => rate.object_id === rateId);
          // Compute the arrival date
          const shipmentDate: moment.Moment = moment(order.shipping?.shippo?.returnShipment.shipment_date);
          if (selectedReturnRate.estimated_days) {
            selectedReturnRate.arrives_by = shipmentDate.add(selectedReturnRate.estimated_days, 'day').toISOString();
          }
        }
      }
      return selectedReturnRate;
    } else if (order.qartOrder?.shippingMethod === 'flat') {
      return null;
    }
    return null;
  }

  /**
   * Computes the estimated delivery date for an order based on its payment date, product lead times, and shipping method.
   * @param order - The order for which to compute the estimated delivery date.
   * @param settings - The settings used to compute the estimated delivery date.
   * @param rate - The shipping rate used to compute the estimated delivery date.
   * @returns The estimated delivery date for the order.
   */
  computeEstimatedDeliveryDate(order: Order, settings: Settings, rate?: any): Date {
    const eventPayment = order.events.history.find(event => event.event === 'payment_succeeded');
    // Get the date at which the order was created
    let orderPaymentDate: Date;
    if (eventPayment) {
      orderPaymentDate = new Date(eventPayment.updatedAt);
    } else {
      orderPaymentDate = new Date(order.createdAt);
    }
    // Add the sum of the lead time of all the products
    const sumProductOrderDuration: moment.Duration = moment.duration({
      millisecond: order.qartOrder.productOrders
        .map((productOrder) => {
          if (productOrder.product.leadTime.type === 'relative') {
            return productOrder.product.leadTime.duration;
          } else {
            return 0;
          }
        })
        .reduce((acc, cur) => acc + cur, 0)
    });
    // Take the maximum with the calendar global lead time
    let minimumDuration: moment.Duration;
    if (settings) {
      const calendarBlockedDelayDuration: moment.Duration = moment.duration({ hour: settings.calendarBlockedDelay });
      minimumDuration = moment.duration({ millisecond: Math.max(calendarBlockedDelayDuration.asMilliseconds(), sumProductOrderDuration.asMilliseconds()) });
    } else {
      minimumDuration = sumProductOrderDuration;
    }
    // Add the estimated number of days for delivery
    let estimatedDeliveryDate: Date;
    if (rate && order.qartOrder?.shippingMethod === 'shippo') {
      rate = rate as ShippoShippingRate;
      estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').add(rate.estimated_days, 'days').toDate();
    } else if (rate && order.qartOrder?.shippingMethod === 'flat') {
      rate = rate as FlatShippingRate;
      estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').add(rate.estimatedDays, 'days').toDate();
    } else {
      estimatedDeliveryDate = moment(orderPaymentDate).add(minimumDuration, 'milliseconds').toDate();
    }
    return estimatedDeliveryDate;
  }


  /**
   * Returns the number of orders for the current merchant based on the given filters.
   * @param filters An object containing filters to apply to the orders.
   * @param options - The options related to cache and propagation.
   * @returns The number of orders.
   */
  countOrders(filters: any = {}, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<number> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(0);
    }
    // If the order count is already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'orderCount', cacheParams)) {
      const orderCount: number = this._cacheService.get(this._cacheNamespace, 'orderCount', cacheParams) || 0;
      if (options.forcePropagate) {
        this._orderCount.next(orderCount);
      }
      return of(orderCount);
    }
    // Otherwise, load the order count from the backend
    return this._httpClient.post<number>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/count`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(0);
        }),
        // Handle the logic of the cache and the propagation
        tap((orderCount: number) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'orderCount', cacheParams, orderCount, options.expire);
          }
          if (options.propagate) {
            this._orderCount.next(orderCount);
          }
        })
      );
  }

  /**
   * Counts the number of products sold based on the given filters.
   * @param filters The filters to apply to the count.
   * @param options - The options related to cache and propagation.
   * @returns The number of products sold in the selected orders.
   */
  countProductsSold(filters: any, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<number> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(0);
    }
    // If the product count is already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'productSoldCount', cacheParams)) {
      const productSoldCount: number = this._cacheService.get(this._cacheNamespace, 'productSoldCount', cacheParams) || 0;
      if (options.forcePropagate) {
        this._productSoldCount.next(productSoldCount);
      }
      return of(productSoldCount);
    }
    // Otherwise, load the product count from the backend
    return this._httpClient.post<number>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/countProducts`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(0);
        }),
        // Handle the logic of the cache and the propagation
        tap((productSoldCount: number) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'productSoldCount', cacheParams, productSoldCount, options.expire);
          }
          if (options.propagate) {
            this._productSoldCount.next(productSoldCount);
          }
        })
      );
  }

  /**
   * Retrieves an order by its ID.
   * @param orderId - The ID of the order to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested order.
   */
  getOrder(orderId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the order is already loaded, return it.
    // If the order is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id, orderId };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'order', cacheParams, false)) {
        this._cacheService.updateStats(true);
        const order: Order = this._cacheService.get(this._cacheNamespace, 'order', cacheParams);
        if (options.forcePropagate) {
          this._order.next(order);
        }
        return of(order);
      }
      // Check if the order is one of the previously loaded sets of orders
      const orderSets: Order[][] = this._cacheService.get(this._cacheNamespace, 'orders', null);
      if (orderSets) {
        for (const orders of orderSets) {
          const order: Order = orders.find(o => o._id === orderId);
          if (order) {
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'order', cacheParams, order, options.expire);
            }
            if (options.propagate) {
              this._order.next(order);
            }
            // Record the cache hit.
            this._cacheService.updateStats(true);
            return of(order);
          }
        }
      }
      // Record the cache miss.
      this._cacheService.updateStats(false);
    }
    // Otherwise, load the product from the backend
    return this._httpClient.get<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', cacheParams, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
          }
        })
      );
  }

  /**
   * Retrieves a list of orders based on the provided filters.
   * @param filters - An object containing filters to apply to the search.
   * @param options - The options related to cache and propagation.
   * @returns The list of requested order objects.
   */
  getOrders(filters: any = {}, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order[]> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the orders are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'orders', cacheParams)) {
      const orders: Order[] = this._cacheService.get(this._cacheNamespace, 'orders', cacheParams);
      if (options.forcePropagate) {
        this._orders.next(orders);
      }
      return of(orders);
    }
    // Otherwise, load the orders from the backend
    return this._httpClient.post<Order[]>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/search`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        map((orders: Order[]) => orders.map(order => this._rebuildOrder(order))),
        // Handle the logic of the cache and the propagation
        tap((orders: Order[]) => {
          if (options.concatenateResults && this._orders.value != null) {
            orders = this._orders.value.concat(orders);
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'orders', cacheParams, orders, options.expire);
          }
          if (options.propagate) {
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Adds a refund to an order.
   * @param orderId The ID of the order to add the refund to.
   * @param productOrderId The ID of the product order to add the refund to.
   * @param quantity The quantity of the refund.
   * @param percentage The percentage of the refund.
   * @param refundStripeFee Whether or not to refund the Stripe fee.
   * @param refundShippingFee Whether or not to refund the shipping fee.
   * @param metadata Additional metadata to include with the refund.
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  addRefund(
    orderId: string,
    productOrderId: number,
    quantity: number,
    percentage: number,
    refundStripeFee: boolean,
    refundShippingFee: boolean,
    metadata: any = null,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/refund/${productOrderId}`,
      {
        quantity,
        percentage,
        refundStripeFee,
        refundShippingFee,
        metadata
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this._errorService.show(error);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Adds a refund of a custom amount to an order.
   * @param orderId The ID of the order to add the refund to.
   * @param amount Amount to be refunded.
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  addCustomRefund(
    orderId: string,
    amount: number,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/custom-refund`,
      { amount },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this._errorService.show(error);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Denies a refund for a product order in an order.
   * @param orderId - The ID of the order.
   * @param productOrderId - The ID of the product order.
   * @param options - The options related to cache and propagation.
   * @returns The updated order.
   */
  denyRefund(orderId: string, productOrderId: number, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/refund/${productOrderId}/deny`,
      {},
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Saves an order on behalf of a customer.
   * @param qartOrder The QartOrder to save.
   * @param customerEmail The email of the customer to save the order on behalf of.
   * @param customerName The name of the customer to save the order on behalf of.
   * @param options - The options related to cache and propagation.
   * @returns The saved order object.
   */
  saveOrderOnBehalf(
    qartOrder: QartOrder,
    customerEmail: string = null,
    customerName: string = null,
    options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES
  ): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/saveOnBehalf`,
      {
        customerName,
        customerEmail,
        qartOrder: qartOrder.toJson()
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next([order, ...orders]);
          }
        })
      );
  }

  /**
   * Updates the tracking number of an order.
   * @param orderId - The ID of the order to update.
   * @param trackingNumber - The new tracking number for the order.
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  updateTrackingNumber(orderId: string, trackingNumber: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/tracking-number`,
      { trackingNumber },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Changes the status of an order.
   * @param orderId - The ID of the order to update.
   * @param status - The new status of the order.#
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  changeOrderStatus(orderId: string, status: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/status`,
      { status },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

  /**
   * Updates the comment of an order.
   * @param orderId - The ID of the order to update.
   * @param newComment - The new comment to set for the order.
   * @param options - The options related to cache and propagation.
   * @returns The updated order object.
   */
  updateComment(orderId: string, newComment: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_5_MINUTES): Observable<Order> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this._httpClient.post<Order>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/orders/${orderId}/comment`,
      { newComment },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        map((order: Order) => order ? this._rebuildOrder(order) : null),
        // Handle the logic of the cache and the propagation
        tap((order: Order) => {
          if (!order) {
            return;
          }
          // Delete all the cache entries that contain the counts (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orderCount', null);
          // Update the cache entries for all the orders (regardless of the filters)
          this._cacheService.delete(this._cacheNamespace, 'orders', null);
          const orders: Order[] = this._orders.getValue() || [];
          const orderIndex = orders.findIndex(o => o._id === order._id);
          if (orderIndex !== -1) {
            orders[orderIndex] = order;
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'order', { merchantId: this._merchant._id, orderId: order._id }, order, options.expire);
          }
          if (options.propagate) {
            this._order.next(order);
            this._orders.next(orders);
          }
        })
      );
  }

}



