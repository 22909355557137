import { FuseNavigationItem } from '@fuse/components/navigation';

/**
 * The default navigation items for the control panel application.
 */
export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'qart',
        title   : '_',
        subtitle: '_',
        type    : 'group',
        icon    : 'heroicons_outline:building-storefront',
        children: [
            {
                id      : 'dashboard',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:squares-2x2',
                link    : '/dashboard'
            },
            {
                id      : 'orders',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:credit-card',
                link    : '/orders'
            },
            {
                id      : 'agenda',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:calendar-days',
                link    : '/agenda'
            },
            {
                id      : 'customers',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:users',
                link    : '/customers'
            },
            {
                id      : 'analytics',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:chart-bar',
                link    : '/analytics'
            },
            {
                id      : 'products',
                title   : '_',
                type    : 'collapsable',
                icon    : 'heroicons_outline:tag',
                link    : '/products',
                children: [
                    {
                        id      : 'products-categories',
                        title   : '_',
                        type    : 'basic',
                        link    : '/categories'
                    },
                    {
                        id      : 'products-products',
                        title   : '_',
                        type    : 'basic',
                        link    : '/products'
                    }
                ]
            },
            {
                id      : 'marketing',
                title   : '_',
                type    : 'collapsable',
                icon    : 'heroicons_outline:megaphone',
                link    : '/marketing',
                children: [
                    {
                        id      : 'coupons',
                        title   : '_',
                        type    : 'basic',
                        link    : '/coupons'
                    },
                    {
                        id      : 'blogs',
                        title   : '_',
                        type    : 'basic',
                        link    : '/blogs'
                    },
                ]
            },
            {
                id      : 'website',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:tv',
                link    : '/website'
            },
            {
                id      : 'merchant',
                title   : '_',
                type    : 'collapsable',
                icon    : 'heroicons_outline:building-storefront',
                link    : '/merchant/basic',
                children: [
                    {
                        id      : 'merchant-basic',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/basic'
                    },
                    {
                        id      : 'merchant-media',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/media'
                    },
                    {
                        id      : 'merchant-social-media',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/social'
                    },
                    {
                        id      : 'merchant-opening-hours',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/opening-hours'
                    },
                    {
                        id      : 'merchant-legal',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/legal'
                    },
                    {
                        id      : 'team',
                        title   : '_',
                        type    : 'basic',
                        link    : '/merchant/team'
                    },
                ]
            },
            {
                id      : 'settings',
                title   : '_',
                type    : 'collapsable',
                icon    : 'heroicons_outline:wrench',
                link    : '/settings/general',
                children: [
                    {
                        id      : 'settings-general',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/general'
                    },
                    {
                        id      : 'settings-security',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/security'
                    },
                    {
                        id      : 'settings-connect',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/connect'
                    },
                    /*
                    {
                        id      : 'settings-taxes',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/taxes'
                    },
                    */
                    {
                        id      : 'settings-invoice',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/invoice'
                    },
                    {
                        id      : 'settings-shipping',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/shipping'
                    },
                    {
                        id      : 'settings-bookings',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/bookings'
                    },
                    {
                        id      : 'settings-team',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/team'
                    },
                    {
                        id      : 'settings-website',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/website'
                    },
                    {
                        id      : 'settings-notifications',
                        title   : '_',
                        type    : 'basic',
                        link    : '/settings/notifications'
                    }
                ]
            },
            {
                id      : 'help-center',
                title   : '_',
                type    : 'basic',
                icon    : 'heroicons_outline:lifebuoy',
                link    : '/help-center'
            }
        ]
    },
    {
        id      : 'external',
        title   : '_',
        subtitle: '_',
        type    : 'group',
        icon    : 'heroicons_outline:link',
        children: [
            {
                id          : 'stripe',
                title       : '_',
                type        : 'basic',
                link        : 'https://dashboard.stripe.com',
                icon        : 'heroicons_outline:link',
                externalLink: true,
                target      : '_blank'
            },
            {
                id          : 'google-merchant',
                title       : '_',
                type        : 'basic',
                link        : 'https://merchants.google.com/mc/overview',
                icon        : 'heroicons_outline:link',
                externalLink: true,
                target      : '_blank'
            },
            {
                id          : 'woocommerce',
                title       : '_',
                type        : 'basic',
                link        : '/dashboard',
                icon        : 'heroicons_outline:link',
                externalLink: true,
                target      : '_blank'
            },
            {
                id          : 'shippo',
                title       : '_',
                type        : 'basic',
                link        : 'https://app.goshippo.com',
                icon        : 'heroicons_outline:link',
                externalLink: true,
                target      : '_blank'
            }
        ]
    }
];

/**
 * An array of compact navigation items.
 */
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'qart',
        title   : '_',
        type    : 'aside',
        icon    : 'heroicons_outline:building-storefront',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'external',
        title   : '_',
        subtitle: '_',
        type    : 'aside',
        icon    : 'heroicons_outline:link',
        children: []
    }
];

/**
 * Navigation data for the futuristic navigation layout.
 */
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'qart',
        title   : '_',
        type    : 'group',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'external',
        title   : '_',
        subtitle: '_',
        type    : 'group',
        icon    : 'heroicons_outline:link',
        children: []
    }
];

/**
 * The horizontal navigation items for the application.
 */
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'qart',
        title   : '_',
        type    : 'group',
        icon    : 'heroicons_outline:building-storefront',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'external',
        title   : '_',
        subtitle: '_',
        type    : 'group',
        icon    : 'heroicons_outline:link',
        children: []
    }
];